
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* color: rgba(252, 146, 59, 0.65) */
    /* color: rgba(255, 208, 158, 0.719) */
    /* background: linear-gradient(360deg, rgba(252, 146, 59, 0.65), rgba(252, 255, 255, 0)) */
    /* background-image: url(''); */
  }
  
  