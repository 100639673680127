@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Roboto+Slab:wght@400;700&family=Lobster&display=swap');

body {
    font-family: 'DM Sans', sans-serif; /* Use DM Sans as the primary font */
  }
  
*{
    box-sizing: border-box
}
html, body{
    overflow-x: hidden;
}
body{
    margin: 0;
    line-height: 1.4;
}

ul {
    list-style: square !important;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.hideScroll {
-ms-overflow-style: none;
scrollbar-width: none;
}

/* :root{
    --fw-bold: 700;
    --fw-normal: 400;
    --bg-lightbrown: #CC9D6B;
    --bg-deepbrown: #3A2613;
    --bg-button: #3D2314;
    --bg-white: #FFFFFF;
    --bg-milkwhite: #FAFAFA;
    --clr-dark: #000000;
    --clr-greyscale: #121127;
    --ff-manrope: 'Manrope';
    --ff-poppins: 'Poppins';
    --ff-sans: 'DM Sans'
}
.nav{
    background-color: var(--bg-white);
    height: 95.61px;
    font-family: var(--ff-sans);
    box-shadow: 0px 2.1px 12.8px rgba(186, 186, 186, 0.48);
}
.head .nav-link{
    font-size: 18px;
    color: var(--clr-dark) !important;
    padding: 4.2px 160.65px;
    font-weight: var(--fw-bold);
    font-family: var(--ff-sans) !important;
}
.nav-colored { background-color: var(--bg-white); }
.nav-transparent { background-color:transparent;}
.head .active{
    color: #AA692D !important;
}
.btn-head{
    background-color: var(--bg-button) !important;
    border-radius: 6px;
    width: 192.4px;
    height: 42.8px;
    color: white !important
}
.btn-head:hover{
    background-color: var(--bg-lightbrown) !important;
}
.alt-grid{
    height: 776px;
    background: var(--bg-lightbrown) !important;
    color: var(--bg-white);
    border-radius: 0px !important;
    font-family: 'Poppins' !important
}
.alt-grid img{
    margin-top: 50px;
    height: 555px !important;
    width: 482px;
}
.row-header{
    margin-top: 67px;
    margin-bottom: 116px;
}
.alt-grid h3{
    font-size: 43px;
    line-height: 1.30;
    font-weight: var(--fw-bold);
}
.alt-grid p{
    color: #3D2314;
    font-size: 20px;
    font-weight: var(--fw-normal);
}
.btn-1{
    background: #FFFFFF !important;
    border-radius: 8px;
    width: 214px;
    height: 52px;
}
.btn-2{
    width: 240px;
    height: 52px;
    color: white !important;
    background-color: #3D2314 !important;
    border-radius: 8px;
} */

/* .section-1{
    height: 770px;
    background-color: #3A2613 !important;
    border-radius: 0px !important;
    mix-blend-mode: normal;
    background-image: url('/images/Group 1000001739 (2).svg');
    background-repeat: no-repeat;
    font-family: var(--ff-poppins);
    background-position: right;
} */
/* .section-1 .row{
    margin-top: 120px;
} */
/* .section-1 h3{
    height: 84px !important;
    font-weight: var(--fw-bold);
    font-size: 60px;
}
.section-1 h6{
    font-size: 19px;
    line-height: 1.4;
    font-weight: var(--fw-normal);
}
.section-1 p{
    font-size: 19px;
    line-height: 1.4;
    font-weight: var(--fw-normal);
}
.section-1 img{
    width: 277px;
    height: 320px;
} */
/* .section-3{
    background-image: url('/images/Group 1000001719.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    font-family: var(--ff-poppins);
    background-color: var(--bg-milkwhite) !important;
    background-size: 270px;
    position: relative;
    padding-bottom: 0px;
}
.section-3 .ribbon{
    position: absolute;
    top: -110px;
    width: 213px;
    height: 213px;
    right: 1240px;
}
.section-3 .ribbon-two{
    position: absolute;
    top: -70px;
    width: 125px;
    height: 125px;
    right: 1120px;
}
.section-3 h3{
    font-size: 57px;
    font-weight: var(--fw-bold);
    color: var(--clr-greyscale);
}
.section-3 h6{
    font-size: 18.5px;
    font-weight: var(--fw-normal);
    line-height: 1.4 !important;
}
.section-3 p{
    font-size: 15px;
    font-weight: var(--fw-normal);
    font-family: var(--ff-manrope) !important;
    line-height: 1.4;
} */
/* .section-4{
    background-image: url('/images/Group 1000001719.svg');
    background-repeat: no-repeat;
    background-position: right;
    font-family: var(--ff-manrope);
    background-color: var(--bg-milkwhite) !important;
    background-size: 240px
}
.section-4 .row-header-4{
    padding-top: 0px
}
.section-4 p{
    font-size: 15px;
    font-weight: var(--fw-normal);
    line-height: 1.4;
} */
/* .section-5{
    height: 763px;
    border-radius: 0px !important;
    background-image: url('/images/image 27.png');
    background-position: center;
    background-size: cover;
    mix-blend-mode: multiply;
    font-family: 'Poppins';
} */
/* .section-5 .btn-3{
    width: 273.54px;
    height: 66.74px;
    background-color: #FFFFFF;
    border: 1px solid #874F1B;
    border-radius: 8px;
    font-size: 16px;
} */
/* .section-5 .btn-4{
    width: 306.54px;
    height: 66.74px;
    font-size: 16px;
    background: #D5A172;
    border-radius: 8px;
} */
/* .section-5 h3{
    margin-top: 200px;
    font-size: 64px;
    font-weight: var(--fw-bold);
}
.section-5 p{
    font-size: 20.4237px;
    color: var(--bg-white);
}

.image-overlay{
    width: 100%;
    height: 763px;
    mix-blend-mode: multiply;
    background-color: #CC9D6B;
}
.section-6 {
    font-family: var(--ff-poppins);
    background-color: var(--bg-milkwhite) !important;
}
.section-6 h3{
    margin-top: 90px;
    font-size: 45px;
    line-height: 140%;
    font-weight: var(--fw-bold);
    color: var(--clr-greyscale);
}
.section-6 p{
    font-size: 17px !important;
    font-weight: var(--fw-normal);
    color: var(--clr-greyscale);
} */
/* .section-7{
    border-radius: 0px !important;
    margin-top: 0px !important;
    background-image: url('/images/Rectangle 34624110.png');
    background-repeat: no-repeat;
    background-position: center;
    font-family: var(--ff-poppins);
    position: relative;
    background-size: cover;
    height: 1095px;
}
.section-7 .ribbon{
    position: absolute;
    top: 10px;
    width: 213px;
    height: 213px;
    right: 1170px;
}
.section-7 .ribbon-two{
    position: absolute;
    top: 45px;
    width: 125px;
    height: 125px;
    right: 1105px;
}
.row-header-7{
    margin-top: 280px
}
.section-7 h3{
    color: white;
    font-weight: var(--fw-bold);
    font-size: 33px;
}
.section-7 .btn-5{
    height: 43.15px;
    background: #D5A172;
    border-radius: 8px;
    padding: 14px 32px;
    font-size: 15.1142px;
    color: var(--bg-white);
    gap: 12px;
}

.section-7 p{
    font-size: 17px;
    color: #D5A172;
}
.section-7 img{
    height: 405px;
    width: 100%;
    border-radius: 20px;
}
.section-9{
    font-family: var(--ff-sans);
    background-color: var(--bg-milkwhite) !important;
    border-radius: 0px !important;
    height: 482.36px;
} */

/* .section-9 h3{
    font-size: 20.9722px;
    line-height: 27px;
    letter-spacing: -0.02em;
    font-weight: var(--fw-bold);
   

    color: #232F3E;
} */
/* .carousel-caption{
    font-family: 'Poppins';
    color: white !important;
    left: 0px;
    top: 270px;
    width: 53% !important;
}
.btn-carousel{
    width: 290px;
    height: 55px;
    background-color: #3AC922 !important;
    font-size: 20px !important;
    color: white !important;
}
.carousel-indicators{
    text-align: left !important;
    float: left !important;
    left: 2% !important;
    right: inherit;
}
.carousel-indicators li{
    width: 9px !important;
    height: 9px !important;
    border-radius: 200%;
    background-color: #C4C4C4 !important;
    left: 300px !important;
} */
/* .carousel-indicators li .active{
    background-color: #31546e !important;
}


.register{
    font-family: 'DM Sans';
    margin-top: 0;
  }
  .remove-all-margin{
    margin:0 !important;
    }

.register-header{
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(151, 218, 141, 0.17);
    height: 72px;
    color: #8692A6;
}
.register-header a{
    color: #3AC922;
    font-weight: bold;
    font-size: 16px;
}
.register-header a:hover{
    color: #267f16;
    text-decoration: none;
}
.register .shift{
    margin-top: 120px;
  }
.sign{
    margin-bottom: 160px;
    margin-left: 40px;
    margin-right: 30px;
}
.sign input[type=text] {
    color: #9CABB5;
}
.sign input[type=password] {
    color: #9CABB5;
}
.sign .text-area {
    color: #9CABB5 !important;
} */
/* .confirm {
    font-size: 14px;
    color: #1E323F;
    margin-left: 50px;
    margin-right:50px;
    align-self: stretch;
}
.sign label{
    color: #1E323F;
    font-size: 14px;
} */
/* form a{
    color: #3AC922 !important;
  } */
/* .login{
    margin-top: 90px !important;
} */
.indexedStep {
    /* color: white; */
    width: 34px;
    height: 34px;
    font-size: 16px;
    background-color: #EFF0F7;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .indexedStep.accomplished {
    background-color: #46AE35;
    color: white
  }
  
  .test {
    position: absolute;
    margin-top: 58px;
    font-size: 12px;
    white-space: nowrap;
  }
  .disable-button{
    background-color: #D0DCE4;
    border: 0px;
  }
  .enable-button{
    background-color: #2B871B;
    border: 0px;
    color: white;
  }

  

  footer{
    margin-top:0px;
    height: 500px;
    background: #281408;
  }
  .small{
    display: none !important;
  }
  footer a{
    color: white
  }
  footer .fab{
    font-size: 25px !important;
  }
  footer .top{
    padding-top: 150px;
  }
  .closebtn{
      display: none;
  }
  .drop-logo{
      display: none;
  }
  .slick-list{
    padding: 0 -3px !important
    }
    .slick-slide {
        padding: 0 3px !important
    }
@media (max-width: 768px) {
    .btn-login{
        width: 100%;
    }
    #collapse-fontawesome{
        background-color: black !important;
    }
    #mynav .fa-bars{
        color: #475661
    }
    #responsive-navbar-nav{
        width: 100%;
        position: fixed;
        height: 680px;
        z-index: 1;
        padding: 15px;
        top: 0;
        left: 0;
        background: #3D2314;
        border-radius: 0px 0px 15px 15px;
        /* background-image: url('/images/Group 1000001719.svg'); */
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 150px;
        overflow-y: hidden;
        transition: 0.5s;
    }
    .drop-logo{
        display: inline;
        margin-bottom: 100px;
    }
    .toggle{
        color: #475661 !important;
        font-size: 30px !important;
    }
    .nav-link{
        text-align: left !important;
        margin-bottom: 20px;
        padding-bottom: 5px;
        transition: 0.3s; 
      }
    .nav-link a:hover{
        color: #f1f1f1
    }
    .closebtn {
        position: absolute;
        display: inline !important;
        top: 15px;
        right: 35px;
        color: var(--bg-white);
        font-size: 60px;
    }
    .head .nav-link{
        color: var(--bg-white) !important;
        font-size: 30px;
        margin-left: 10px !important;
    }
    .head .active{
        text-decoration: underline;
    }
    
    .nav-link button{
       display: none;
    }
    
    .alt-grid img{
        margin-top: 70px;
        height: 330px !important;
        text-align: center;
        width: 269px !important;
    }
    .alt-grid .row-header{
        margin-top: 0px !important;
        padding-bottom: 0 !important;
        margin-bottom: 0px !important;
    }
    .alt-grid h3{
        margin-top: 40px !important;
        line-height: 1.31;
        font-size: 32px;
    }
    .alt-grid{
        height: auto;
    }
    .hide-login{
        display: none !important;
    }
    .btn-1{
        height: 52px;
        width: 100%;
    }
    .btn-2{
        height: 52px;
        width: 100%;
        margin-bottom: 100px !important;
    }
    .alt-grid .ml-3{
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
    .section-1{
        height: 1050px;
        background-color: #3A2613 !important;
        border-radius: 0px !important;
        mix-blend-mode: normal;
        /* background-image: url('/images/Group 1000001739 (2).svg'); */
        background-repeat: no-repeat;
        background-size: 253px;
        font-family: 'Poppins';
        background-position: 270px 20px;
    }
    
    .section-1 img{
        height: 345px;
    }
    .section-1 .img-position{
        text-align: center;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
    .section-1 h3{
        margin-top: 0 !important;
        padding-top: 0 !important;
        font-size: 40px;
        line-height: 1.4;
    }
    .section-1 p{
        padding-bottom: 80px;
    }
    
    .section-3{
        background-image: url('');
    }
    .section-3 h3{
        padding-top: 124px;
        font-size: 34px;
        text-align: center !important;
    }
    .section-3 h6{
        margin-top: 30px;
        text-align: center !important;
        font-size: 16px;
    }
    .section-3 .ribbon{
        top: -80px;
        width: 141.16px;
        height: 141.16px;
        right: 320px;
    }
    .section-3 .ribbon-two{
        top: -50px;
        width: 82.84px;
        height: 82.84px;
        right: 240px;
    }
    /* .section-4 .ribbon{
        position: absolute;
        transform: rotate(90deg);
        top: 480px;
        right: 10px;
        width: 100%;
        height: 199px;
    } */
    .section-3 .card{
        height: 344px !important;
        background: #F6F6F6 !important;
        box-shadow: 0px 2.75946px 2.75946px rgba(193, 193, 193, 0.25) !important;
        border-radius: 17.2466px !important;
        margin-top: 50px !important;
        margin-bottom: 120px !important;
    }
    
    .section-4 .row-header-4{
        padding-top: 0px !important;
    }
    .section-5 h3{
        margin-top: 230px;
        font-size: 35px;
    }
    .section-5 p{
        padding-top: 25px !important;
    }
    .section-5 .btn-3{
        margin-top: 25px !important;
        padding: 14px 0px;
        gap: 12px;
        height: 66.74px;
        width: 100%;
    }
    .section-5 .btn-4{
        padding: 14px 32px;
        gap: 12px;
        height: 66.74px;
        width: 100%;
    }
    .section-5 .ml-3{
        margin-left: 0 !important;
    }
    .section-5 .pl-5{
        text-align: center;
        padding-left: 0px !important;
    }
    .section-6 {
        height: 1000px
    }
    .section-6 h3{
        font-size: 38px;
    }
    .section-6 p{
        font-size: 18px !important;
        color: #121127;
    }
    .section-6 marquee img{
        margin-top: 30px;
    }
    .section-7{
        height: 1300px;
        /* background-image: url('/images/Rectangle 34624110 (2).png'); */
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
    .section-7 .ribbon{
        position: absolute;
        top: 43px;
        width: 90.12px;
        height: 90.12px;
        right: 325px;
    }
    .section-7 .ribbon-two{
        position: absolute;
        top: 53px;
        width: 52.88px;
        height: 52.88px;
        right: 298px;
    }
    .section-7 img{
        height: 405px;
    }
    .section-7 h3{
        margin-top: 70px;
        font-size: 25px;
        line-height: 38px;
    }
    .section-7 p{
        margin-top: 20px;
        font-size: 18px;
    }
    .section-7 .btn-5{
        height: 67px;
        width: 100%;
        font-size: 15.1142px;
    }
    .slick-list{
        padding: 0 -12px !important
    }
    .slick-slide {
        padding: 0 12px !important
    }
    .row-header-7{
        margin-top: 200px
    }
    .section-9 {
        background-color: var(--bg-milkwhite) !important;
        height: 631px;
        text-align: center;
    }
    .section-9 .container{
        margin-top: 40px;
    }
    .section-9 h3{
        font-size: 23.0123px;
        line-height: 30px;
        letter-spacing: -0.02em;
        color: #232F3E;
    }
    .section-9 img{
        margin-bottom: 60px;
    }
    footer{
        height: 502px;
        text-align: left !important;
      }
    footer .top{
        padding-top: 60px;
      }
      footer .link li{
        display: block !important;
        margin-bottom: 20px !important;
        font-size: 16.7778px;
        text-align: left !important
      }
      footer .fab{
        font-size: 25px !important;
      }
      footer ul .ml-4{
        text-align: left !important;
        justify-content: left;
      }
      .copyright{
          margin-left: 22px;
          text-align: center !important;
      }
    .test{
        font-size: 10.081px;
    }
    .sign input[type=text] {
        font-size: 12px !important;
    }
    .sign .text-area {
        font-size: 12px !important;
    }
    .sign input[type=text-area] {
        font-size: 12px !important;
    }
    .big{
        display: none !important;
    }
    .small{
        display: inline !important;
    }
    .sign{
        margin-left: 0px;
        margin-right: 0px;
    }
    .confirm {
        font-size: 14px;
        color: #1E323F;
        margin-left: 50px;
        margin-right: 10px;
        align-self: stretch;
    }
    .register .left{
        margin-top: 80px;
      }
    .register .left h1{
        font-size: 24px ;
      }
    .register .shift{
        margin-top: 60px;
        padding-top: 0;
      }
    .register-header{
        display: none !important;
    }
    .indexedStep{
        font-size: 12.7645px;
    }
    
}    